import React from "react"
import { Label, Paragraph, Button } from "../components/atomics"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { URL_EXTENSION_CHROME, URL_EXTENSION_FIREFOX } from "../constants"

const Extension = () => {

  return (
    <Layout>
      <SEO
        title="Extensão"
        description="Extensão para o aplicativo Flynow compatível com o Google Chrome e Firefox"
      />
      <br />
      <h1>Extensão para Chrome e Firefox</h1>
      <Paragraph>
        Com a extensão é possível visualizar e marcar como concluída rapidamente
        todas as suas tarefas, hábitos e metas pelo computador. A extensão está
        disponível para os navegadores Google Chrome e Firefox
      </Paragraph>
      <br />
      <Label>Como acessar a Extensão?</Label>
      <Paragraph>
        A Extensão do Flynow é disponível apenas para usuários premium. Se você
        ainda não é um usuário premium, você pode se tornar premium através do
        aplicativo.
        <br />
        Para acessar a extensão, instale a extensão clicando no botão abaixo
        referente ao seu navegador, faça o login com sua conta do Flynow e
        utilize à vontade!
      </Paragraph>
      <Paragraph>
        <Button
          href={URL_EXTENSION_CHROME}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            width: "100%",
            color: "white",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {" "}
          Instalar extensão do Chrome
        </Button>
        <Button
          href={URL_EXTENSION_FIREFOX}
          target="_blank"
          rel="noopener noreferrer"
          style={{
            width: "100%",
            marginTop: 10,
            color: "white",
            alignItems: "center",
            justifyContent: "center",
            display: "flex",
          }}
        >
          {" "}
          Instalar extensão do Firefox
        </Button>
      </Paragraph>
      <iframe
      className="video-youtube"
        src="https://www.youtube.com/embed/JBOqIY0iYaI?si=lupnTSqMtO61iuvi&amp;start=319"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
    </Layout>
  )
}

export default Extension
